<template>
    <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-xl">
        <div class="tw-flex tw-flex-row xl:tw-space-x-8">
            <div class="tw-hidden xl:tw-block xl:tw-w-44 tw-flex-shrink-0">
                <div v-sticky="{ zIndex: 1, stickyTop: 110, disabled: false }">
                    <div class="tw-flex tw-flex-col tw-space-y-4 tw-text-right">
                        <a
                            @click="$vuetify.goTo('#Odkaz', { offset: 145 })"
                            class="tw-text-base tw-text-gray-800 tw-no-underline hover:tw-text-gray-500 tw-transition-colors tw-duration-200"
                        >
                            {{ $t('profile.link') }}
                        </a>
                        <a
                            @click="$vuetify.goTo('#Jazyk', { offset: 100 })"
                            class="tw-text-base tw-text-gray-800 tw-no-underline hover:tw-text-gray-500 tw-transition-colors tw-duration-200"
                        >
                            {{ $t('profile.language') }}
                        </a>
                        <a
                            @click="$vuetify.goTo('#kontaktne-udaje', { offset: 100 })"
                            class="tw-text-base tw-text-gray-800 tw-no-underline hover:tw-text-gray-500 tw-transition-colors tw-duration-200"
                        >
                            {{ $t('profile.contact_information') }}
                        </a>
                        <a
                            @click="$vuetify.goTo('#Súkromie', { offset: 100 })"
                            class="tw-text-base tw-text-gray-800 tw-no-underline hover:tw-text-gray-500 tw-transition-colors tw-duration-200"
                        >
                            {{ $t('profile.privacy') }}
                        </a>
                        <a
                            @click="$vuetify.goTo('#Graf', { offset: 100 })"
                            class="tw-text-base tw-text-gray-800 tw-no-underline hover:tw-text-gray-500 tw-transition-colors tw-duration-200"
                        >
                            {{ $t('profile.graph') }}
                        </a>
                    </div>
                </div>
            </div>

            <div class="tw-w-full">
                <div class="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-8 lg:tw-space-y-0 lg:tw-space-x-8">
                    <section class="tw-w-full">
                        <div class="tw-bg-white tw-rounded-2xl tw-p-4 lg:tw-p-8">
                            <form @submit.prevent="submit">
                                <h3 id="Odkaz" class="tw-heading-3">{{ $t('profile.link') }}</h3>
                                <p class="tw-mt-2">{{ $t('profile.descriptions.resume_link') }}</p>
                                <k-input
                                    v-model="cvUrl"
                                    type="text"
                                    id="cvURL"
                                    ref="cvURL"
                                    prefix="koderia.sk/cv/"
                                    tabindex="-1"
                                    class="cv-url-input tw-mt-6"
                                />
                                <p class="tw-text-red-500 tw-mt-1 tw-italic" v-show="formUrlState === 'ERROR'">{{ formUrlStateMessage }}</p>

                                <k-button
                                    @click="copyToClipboard(`${baseUrl}/cv/${cvUrl}`)"
                                    :disabled="!cvUrl"
                                    :success="successfullyCopied"
                                    type="button"
                                    class="tw-mt-4"
                                >
                                    {{ $t('global.actions.copy') }}
                                </k-button>

                                <h3 id="Jazyk" class="tw-mt-12 tw-heading-3">{{ $t('profile.language') }}</h3>
                                <p class="tw-mt-2">{{ $t('profile.descriptions.resume_language') }}</p>
                                <k-select v-model="form.language" :items="languages" :placeholder="$t('profile.language')" class="tw-mt-6" />

                                <h3 id="kontaktne-udaje" class="tw-mt-12 tw-heading-3">{{ $t('profile.contact_information') }}</h3>
                                <p class="tw-mt-2 lg:tw-max-w-lg">
                                    {{ $t('profile.descriptions.resume_social_contact') }}
                                </p>
                                <v-radio-group
                                    @change="handleShowContactChangedByUser"
                                    v-model="form.showContact"
                                    hide-details
                                    row
                                    style="padding: 0;"
                                    class="tw-mt-6"
                                >
                                    <k-radio :value="true" :label="$t('global.yes')" />
                                    <k-radio :value="false" :label="$t('global.no')" class="ml-6" />
                                </v-radio-group>

                                <h3 id="Súkromie" class="tw-mt-12 tw-heading-3">{{ $t('profile.privacy') }}</h3>
                                <p class="tw-mt-2">{{ $t('profile.descriptions.resume_availability') }}</p>
                                <v-radio-group v-model="form.audience" hide-details class="tw-p-0 tw-mt-6">
                                    <k-radio :label="$t('profile.public')" value="public" />
                                    <k-radio :label="$t('profile.registered_users')" value="users" />
                                    <k-radio :label="$t('profile.private')" value="private" />
                                    <k-radio :label="$t('profile.accessible_with_password')" value="token" />
                                </v-radio-group>
                                <div v-show="form.audience === 'token'" class="tw-mt-6">
                                    <k-input v-model="form.token" @keyup="formatToken" :title="$t('global.inputs.password')" />
                                    <p class="mt-2">
                                        <strong>{{ $t('profile.descriptions.account_password_not_allowed') }}</strong> {{ $t('profile.descriptions.resume_password_viewing_only') }}
                                        <!-- <br />
                                        <small>
                                            Maximálna dĺžka 64 znakov. Môže obsahovať len číslice, písmená, podčiarkovník (_) a spojovník (-).
                                        </small> -->
                                    </p>
                                    <p class="tw-mt-6">
                                        <span>koderia.sk/cv/</span>
                                        <span>{{ userCv ? userCv.url : "" }}</span>
                                        <span class="tw-text-koderia">?token={{ form.token }}</span>
                                    </p>
                                </div>

                                <h3 id="Graf" class="tw-mt-12 tw-heading-3">{{ $t('profile.graph') }}</h3>
                                <p class="tw-mt-2">{{ $t('profile.descriptions.digital_experience_graph') }}</p>
                                <v-radio-group v-model="form.showChart" hide-details row class="tw-mt-6 tw-p-0">
                                    <k-radio :value="true" :label="$t('global.yes')" />
                                    <k-radio :value="false" :label="$t('global.no')" class="ml-6" />
                                </v-radio-group>

                                <div v-if="!hasUserCv" class="tw-mt-6 tw-border-0 tw-border-t tw-border-solid tw-border-gray-300">
                                    <k-gdpr
                                        class="tw-mt-6"
                                        v-model="gdpr"
                                        :showNewsletter="false"
                                        :invalid="submitted && (!gdpr || !gdpr.gdprValue)"
                                        :gdprTooltip="$t('gdpr.descriptions.privacy_policy_tooltip')"
                                    />
                                </div>

                                <k-button color="primary" type="submit" class="tw-mt-12" :loading="formState === 'PENDING'">
                                    {{ $t('global.actions.save') }}
                                </k-button>

                                <p class="tw-text-red-500 tw-mt-1 tw-italic" v-show="formState === 'ERROR'">{{ formStateMessage }}</p>
                            </form>
                        </div>
                    </section>

                    <div class="tw-hidden lg:tw-block lg:tw-w-84 tw-flex-shrink-0">
                        <!-- Empty -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sticky from "vue-sticky";

export default {
    directives: {
        sticky: Sticky
    },
    data() {
        return {
            cvUrl: null,
            successfullyCopied: false,

            submitted: false,
            gdpr: null,
            languages: [
                {
                    value: "sk",
                    text: "Slovenský"
                },
                {
                    value: "en",
                    text: "Anglický"
                }
            ],
            form: {
                showContactChangedByUser: false,
                showContact: true,
                showChart: true,
                audience: "private",
                token: null,
                language: "sk"
            }
        };
    },
    computed: {
        baseUrl() {
            return window.location.origin;
        },
        user() {
            return this.$store.state.AUTH.user;
        },
        userCv() {
            return this.$store.state.USER.userCv;
        },
        hasUserCv() {
            return this.$store.state.USER.hasUserCv;
        },
        formState: function() {
            return this.$store.state.USER.formState.userCv;
        },
        formStateMessage: function() {
            return this.$store.state.USER.formStateMessage.userCv;
        },
        formUrlState() {
            return this.$store.state.USER.formState.userCvUrl;
        },
        formUrlStateMessage() {
            return this.$store.state.USER.formStateMessage.userCvUrl;
        }
    },
    watch: {
        "form.audience": {
            immediate: true,
            handler(value) {
                if (!this.form || this.form.showContactChangedByUser) {
                    return;
                }

                if (value === "public") {
                    this.form.showContact = false;
                } else if (value === "private") {
                    this.form.showContact = true;
                }
            }
        },

        "userCv": {
            deep: true,
            immediate: true,
            handler(value) {
                if (value && value.url) {
                    this.cvUrl = value.url;
                } else {
                    // Compute unique link for user
                    const { email = "" } = this.user || {};
                    let name = email.substring(0, email.indexOf("@"));
                    name = name.replace(/[^a-z0-9]/gim, "").replace(/\s+/g, "");
                    name = name.toLowerCase();
                    this.cvUrl = name.trim();
                }

                const { settings } = value || {};
                const { showChart = true, showContact, showContactChangedByUser, audience, token, language } = settings || {};

                this.form.showContactChangedByUser = showContactChangedByUser || false;
                this.form.audience = audience || "private";

                if (this.form.showContactChangedByUser) {
                    this.form.showContact = showContact || false;
                } else if (this.form.audience === "private") {
                    this.form.showContact = true;
                } else if (this.form.audience === "public") {
                    this.form.showContact = false;
                } else {
                    this.form.showContact = false;
                }

                this.form.showChart = !!showChart;
                this.form.token = token || null;
                this.form.language = language || "sk";
            }
        },
        "formState"(state) {
            if (state === "SUCCESS") {
                setTimeout(() => {
                    this.$store.commit("USER/SET_FORM_STATE", {
                        name: "userCv",
                        state: "IDLE",
                        message: null
                    });
                }, 2500);
            }
        }
    },
    methods: {
        copyToClipboard(str) {
            const el = document.createElement("textarea");
            el.value = str;
            el.setAttribute("readonly", "");
            // Make el invisible
            el.style.position = "absolute";
            el.style.width = "0.063rem";
            el.style.height = "0.063rem";
            el.style.left = "-9999rem";
            el.style.clip = "rect(0, 0, 0, 0);";
            el.style.overflow = "hidden";
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);

            // Show the user that the link has been successfully copied
            this.successfullyCopied = true;
            setTimeout(() => {
                this.successfullyCopied = false;
            }, 2500);
        },
        submitUrl() {
            const data = this.cvUrl
                ? String(this.cvUrl)
                      .trim()
                      .toLowerCase()
                : null;

            if (data && data.length > 0 && data != String(this.userCv.url).toLowerCase()) {
                this.$store.dispatch("USER/UPDATE_CV_URL", data);
            }
        },
        submit() {
            this.submitted = true;

            if (!this.hasUserCv && (!this.gdpr || !this.gdpr.gdprValue)) {
                return;
            }

            this.submitUrl();

            const { settings } = this.userCv || {};
            const data = {
                ...settings,
                showContactChangedByUser: !!this.form.showContactChangedByUser,
                showContact: !!this.form.showContact,
                showChart: !!this.form.showChart,
                audience: this.form.audience || "private",
                token: this.form.audience === "token" ? this.form.token : null,
                language: this.form.language || "sk"
            };

            this.$store.dispatch("USER/UPDATE_CV", { settings: data });
            this.$emit("submit");
        },
        formatToken() {
            if (!this.form.token) return "";
            const token = String(this.form.token)
                .replace(/[^a-zA-Z0-9_-]/gim, "")
                .replace(/\s+/g, "")
                .trim()
                .substring(0, 64);
            this.form.token = token;
        },
        handleShowContactChangedByUser() {
            this.form.showContactChangedByUser = true;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";
::v-deep .cv-url-input .v-text-field__prefix {
    color: #7f7f7f !important;
    font-size: 0.875rem !important;
    padding: 0 !important;
}
</style>
