import { render, staticRenderFns } from "./ka-cv-form-section-profile.vue?vue&type=template&id=707b26b9&scoped=true&"
import script from "./ka-cv-form-section-profile.vue?vue&type=script&lang=js&"
export * from "./ka-cv-form-section-profile.vue?vue&type=script&lang=js&"
import style0 from "./ka-cv-form-section-profile.vue?vue&type=style&index=0&id=707b26b9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "707b26b9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KCombobox from '@web/components/core/k-combobox'
import KIcon from '@web/components/core/k-icon'
import KInput from '@web/components/core/k-input'
import KTextarea from '@web/components/core/k-textarea'
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {KButton,KCombobox,KIcon,KInput,KTextarea,VCol,VRow})
