<template>
    <div class="wrapper">
        <div class="head">
            <span class="tw-text-sm tw-leading-7 tw-text-gray-800 tw-font-semibold">{{ $t('profile.stack') }}</span>
            <span class="tw-text-sm tw-leading-7 tw-text-gray-800 tw-font-semibold">{{ $t('profile.years_of_experience') }}</span>
            <span style="width: 44px"></span>
        </div>

        <div class="form-item-grid" v-for="(row, idx) in value" :key="idx">
            <k-combobox
                v-model="row.name"
                :items="allTechnologiesWithoutSelected"
                :placeholder="$t('profile.inputs.technology_software_activity')"
                class="tw-w-full"
                style="grid-area: tech;"
            />
            <k-select
                v-model="row.experience"
                :items="allExperiences"
                :placeholder="$t('profile.inputs.numbers_of_years_of_experience')"
                class="tw-w-full"
                style="grid-area: prax;"
            />

            <k-button
                v-if="idx < value.length - 1"
                @click="remove(idx)"
                color="red-outlined"
                icon
                style="grid-area: remove;"
                rounded
            >
                <k-icon name="trash" size="14" />
            </k-button>
            <k-button
                v-else
                icon
                style="grid-area: remove;"
                class="tw-invisible"
            >
                <k-icon name="trash" size="14" />
            </k-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    components: {},
    props: {
        value: {
            type: Array,
            default: () => []
        },
        allSelectedExperiencesNames: {
            type: Array,
            default: () => []
        },
        moveUpDisabled: {
            type: Boolean,
            default: false
        },
        moveDownDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            newValue: {
                name: null,
                experience: null
            },
        };
    },
    computed: {
        ...mapGetters("ENUMS", {
            allTechnologies: "allTechnologiesNames"
        }),
        allExperiences() {
            return [
                { value: "0-1", text: this.$t('global.time.year_less') },
                { value: "1", text: this.$t('global.time.year_1') },
                { value: "2", text: this.$tc('global.time.count_years', 2) },
                { value: "3", text: this.$tc('global.time.count_years', 3) },
                { value: "4", text: this.$tc('global.time.count_years', 4) },
                { value: "5-10", text: this.$t('global.time.year_5_10') },
                { value: "10+", text: this.$t('global.time.year_10_more') }
            ];
        },
        allTechnologiesWithoutSelected() {
            return (this.allTechnologies || []).filter(name => (this.allSelectedExperiencesNames || []).indexOf(name) === -1);
        }
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                if (!value || value.length < 1) {
                    this.add({ name: null, experience: null });
                    return;
                }

                const last = value && value.length > 0 ? value[value.length - 1] : {};
                const { name, experience } = last;
                if (name || experience) {
                    this.add({ name: null, experience: null });
                }
            }
        }
    },
    methods: {
        add({ name = null, experience = null }) {
            const items = [...this.value];
            items.push({ name, experience });
            this.$emit("input", items);
        },
        remove(i) {
            let items = [...this.value];
            items = items.slice(0, i).concat(items.slice(i + 1, items.length));
            this.$emit("input", items);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.wrapper {
    min-height: 311px;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    .head {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        column-gap: 1.5rem;
        margin-bottom: -0.5rem;
    }

    @media screen and (max-width: 576px) {
        .form-item-grid:not(:last-child) {
            padding-bottom: 1.5rem;
            border-bottom: 1px solid $koderia-gray-200;
        }
    }
}

.form-item-grid {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-areas:
        "tech remove"
        "prax remove";
    grid-template-columns: 1fr 3.5rem;
    grid-template-rows: 1fr 1fr;
    gap: 1.5rem;

    @include md {
        grid-template-areas: "tech prax remove";
        grid-template-columns: 1fr 1fr auto;
        grid-template-rows: 1fr;
        gap: 1.5rem;
    }

    @media screen and (max-width: 567px) {
        gap: 0.5rem;
    }
}
</style>
