<template>
    <ka-cv-form-section-item-base v-bind="$attrs" v-on="$listeners">
        <div class="tw-flex tw-flex-col-reverse sm:tw-flex-row tw-space-y-reverse tw-space-y-4 sm:tw-space-y-0 sm:tw-space-x-4">
            <div class="tw-w-full tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                <k-input v-if="showInputLabels" v-model="form.language" :title="$t('profile.inputs.language')" />
                <k-input v-else v-model="form.language" />

                <k-select
                    v-if="showInputLabels"
                    v-model="form.level"
                    :items="levels"
                    :title="$t('profile.inputs.level')"
                />
                <k-select
                    v-else
                    v-model="form.level"
                    :items="levels"
                />
            </div>
            <ka-cv-form-section-item-actions
                @move-up="$emit('move-up')"
                @move-down="$emit('move-down')"
                @remove="$emit('remove')"
                :moveUpDisabled="moveUpDisabled"
                :moveDownDisabled="moveDownDisabled"
                class="tw-self-end"
            />
        </div>
    </ka-cv-form-section-item-base>
</template>

<script>
import KaCvFormSectionItemBase from "./ka-cv-form-section-item-base";
import KaCvFormSectionItemActions from "./ka-cv-form-section-item-actions";

import { LANGUAGE_LEVELS, CEFR_LEVELS } from "./../constant";

export default {
    components: {
        KaCvFormSectionItemBase,
        KaCvFormSectionItemActions
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        moveUpDisabled: {
            type: Boolean,
            default: false
        },
        moveDownDisabled: {
            type: Boolean,
            default: false
        },
        showInputLabels: {
            type: Boolean,
            default: true
        }
    },
    data() {
        const levels = [];

        levels.push({ header: "Jazyková úroveň" });
        Object.keys(LANGUAGE_LEVELS["sk"]).forEach(key => {
            levels.push({ value: key, text: LANGUAGE_LEVELS["sk"][key] });
        });

        levels.push({ header: "CEFR" });
        Object.keys(CEFR_LEVELS["sk"]).forEach(key => {
            levels.push({ value: key, text: CEFR_LEVELS["sk"][key] });
        });

        return {
            levels,
            form: { ...this.extractDataModelFrom("value") }
        };
    },
    watch: {
        form: {
            deep: true,
            handler() {
                this.$emit("input", this.extractDataModelFrom("form"));
            }
        }
    },
    methods: {
        extractDataModelFrom(variableName) {
            const { language = null, level = null } = this[variableName] || {};
            return { language, level };
        }
    }
};
</script>
