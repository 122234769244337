<template>
    <div class="tw-bg-transparent tw-space-y-3">
        <template v-if="!!userCv">
            <div v-if="!hasUserCv" class="tw-mb-4">
                <k-gdpr
                    v-model="gdpr"
                    :showNewsletter="false"
                    :invalid="submitted && (!gdpr || !gdpr.gdprValue)"
                    :gdprTooltip="$t('gdpr.descriptions.privacy_policy_tooltip')"
                />
            </div>

            <div class="tw-flex tw-items-center">
                <k-button color="primary" class="tw-w-full" size="lg" @click="submit" :loading="loading" :success="success">
                    <k-icon name="save" :size="18" class="tw-mr-1" />
                    {{ $t('global.actions.save') }}
                </k-button>
            </div>

            <div class="tw-grid tw-grid-cols-2 tw-gap-x-3">
                <k-button @click="$emit('save-pdf')" v-if="hasUserCv" :loading="loadingPdfUrl" color="secondary" :disabled="loadingPdfUrl">
                    <k-icon name="download" :size="18" class="tw-mr-1" />
                    {{ $t('global.actions.download') }}
                </k-button>

                <k-button :href="`${localePrefix}/cv/${showCvUrl}`" target="_blank" v-if="hasUserCv" color="secondary">
                    <k-icon name="link_outside" :size="18" class="tw-mr-1" />
                    {{ $t('profile.actions.show_resume') }}
                </k-button>
            </div>
            <!--
            <div class="tw-w-full tw-text-center tw-mt-4">
                <p>
                    {{ $t('profile.import_your_data_from') }}
                    <a
                        @click="$emit('linkedin-import')"
                        class="tw-font-bold tw-underline hover:tw-text-koderia tw-transition-colors tw-duration-75 tw-ease-in"
                    >
                        {{ $t('profile.linkedin') }}.
                    </a>
                </p>
            </div>
            -->
        </template>
    </div>
</template>

<script>
import {getRouteLocale} from '@web/constants/language'

export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        loadingPdfUrl: {
            type: Boolean,
            default: false
        },
        success: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            gdpr: undefined,
            submitted: false
        };
    },
    computed: {
        localePrefix() {
            const locale = getRouteLocale();

            return locale ? `/${locale}` : '';
        },
        userCv() {
            return this.$store.state.USER.userCv;
        },
        hasUserCv() {
            return this.$store.state.USER.hasUserCv;
        },
        showCvUrl() {
            const { url, settings } = this.userCv || {};
            const { token } = settings || {};

            if (token) {
                return url + "?token=" + token;
            }

            return url;
        }
    },
    methods: {
        submit() {
            this.submitted = true;

            if (!this.hasUserCv && (!this.gdpr || !this.gdpr.gdprValue)) {
                return;
            }

            this.$emit("submit");
        }
    }
};
</script>

<style></style>
