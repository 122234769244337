<template>
    <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-xl">
        <ka-user-cv-linkedin-modal @response="fillLinkedinImportResponse" :value="linkedinDialog" @close="linkedinDialog = false" />

        <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-space-x-8">
            <div class="tw-hidden xl:tw-block xl:tw-w-44 tw-flex-shrink-0">
                <div v-sticky="{ zIndex: 1, stickyTop: 110, disabled: false }">
                    <div class="tw-flex tw-flex-col tw-space-y-4 tw-text-right">
                        <a
                            v-for="link in links"
                            :key="link.href"
                            @click="$vuetify.goTo(link.href, { offset: link.offset })"
                            class="tw-text-base tw-text-gray-800 tw-no-underline hover:tw-text-gray-500 tw-transition-colors tw-duration-200"
                        >
                            {{ $t(link.label) }}
                        </a>
                    </div>
                </div>
            </div>

            <div class="tw-flex-grow">
                <div class="tw-flex tw-flex-col">
                    <section class="tw-bg-white tw-rounded-2xl tw-p-4 lg:tw-p-10">
                        <ka-cv-form-section-profile @avatar="submit" v-model="form.general" />
                    </section>

                    <section class="tw-mt-10">
                        <ka-cv-form-section
                            id="employments"
                            :title="$t('global.employment')"
                            :sub-title="$t('profile.descriptions.employment')"
                            :addTitle="orders.employment.length ? this.$t('global.actions.add_more') : this.$t('global.actions.add')"
                            @add="addSectionItem('employment')"
                        >
                            <template #icon>
                                <k-icon name="laptop" :size="20" />
                            </template>

                            <div v-for="(id, idx) in orders.employment" :key="`${id}_${idx}`">
                                <ka-cv-form-section-item-employment
                                    v-model="form.employment[id]"
                                    :sub-title="`${$t('global.employment')} ${idx + 1}`"
                                    @remove="removeSectionItem('employment', id)"
                                    @move-up="moveUpSectionItem('employment', id)"
                                    @move-down="moveDownSectionItem('employment', id)"
                                    :move-up-disabled="idx == 0"
                                    :move-down-disabled="idx == orders.employment.length - 1"
                                    :bottom-line="idx < orders.employment.length - 1"
                                />
                            </div>
                        </ka-cv-form-section>
                    </section>

                    <section class="tw-mt-10">
                        <ka-cv-form-section
                            id="stack"
                            :title="$t('profile.stack')"
                            :sub-title="$t('profile.descriptions.stack')"
                            simple
                        >
                            <template #icon>
                                <k-icon name="layer_group" :size="20" />
                            </template>

                            <template #divider>
                                <p class="tw-bg-blue-light tw-text-blue tw-text-sm tw-leading-relaxed tw-py-1 tw-px-3 tw-my-6 tw-rounded-md">
                                    {{ $t('profile.descriptions.stack_notify') }}
                                </p>
                            </template>

                            <ka-cv-form-section-item-experience v-model="form.experience" :allSelectedExperiencesNames="allSelectedExperiencesNames" />
                        </ka-cv-form-section>
                    </section>

                    <section class="tw-mt-10">
                        <ka-cv-form-section
                            id="education"
                            :title="$t('profile.education')"
                            :sub-title="$t('profile.descriptions.education')"
                            :addTitle="$t(orders.education.length ? 'global.actions.add_more' : 'global.actions.add')"
                            @add="addSectionItem('education')"
                        >
                            <template #icon>
                                <k-icon name="user_graduate" :size="20" />
                            </template>

                            <div v-for="(id, idx) in orders.education" :key="`${id}_${idx}`">
                                <ka-cv-form-section-item-education
                                    v-model="form.education[id]"
                                    :sub-title="`${$t('profile.school')} ${idx + 1}`"
                                    @remove="removeSectionItem('education', id)"
                                    @move-up="moveUpSectionItem('education', id)"
                                    @move-down="moveDownSectionItem('education', id)"
                                    :move-up-disabled="idx == 0"
                                    :move-down-disabled="idx == orders.education.length - 1"
                                    :bottom-line="idx < orders.education.length - 1"
                                />
                            </div>
                        </ka-cv-form-section>
                    </section>

                    <section class="tw-mt-10">
                        <ka-cv-form-section
                            id="references"
                            :title="$t('profile.references')"
                            :sub-title="$t('profile.descriptions.references')"
                            :addTitle="orders.reference.length ? $t('global.actions.add_more') : $t('global.actions.add')"
                            @add="addSectionItem('reference')"
                        >
                            <template #icon>
                                <k-icon name="rocket" :size="16" />
                            </template>

                            <div v-for="(id, idx) in orders.reference" :key="`${id}_${idx}`">
                                <ka-cv-form-section-item-reference
                                    v-model="form.reference[id]"
                                    :sub-title="`${$t('profile.reference')} ${idx + 1}`"
                                    @remove="removeSectionItem('reference', id)"
                                    @move-up="moveUpSectionItem('reference', id)"
                                    @move-down="moveDownSectionItem('reference', id)"
                                    :move-up-disabled="idx == 0"
                                    :move-down-disabled="idx == orders.reference.length - 1"
                                    :bottom-line="idx < orders.reference.length - 1"
                                />
                            </div>
                        </ka-cv-form-section>
                    </section>

                    <section class="tw-mt-10">
                        <ka-cv-form-section
                            id="certificates"
                            :title="$t('profile.certificates')"
                            :sub-title="$t('profile.descriptions.certificates')"
                            :addTitle="orders.certificate.length ? this.$t('global.actions.add_more') : this.$t('global.actions.add')"
                            @add="addSectionItem('certificate')"
                        >
                            <template #icon>
                                <k-icon name="certificate" :size="20" />
                            </template>

                            <div v-for="(id, idx) in orders.certificate" :key="`${id}_${idx}`">
                                <ka-cv-form-section-item-certificate
                                    v-model="form.certificate[id]"
                                    :sub-title="`${$t('profile.certificate')} ${idx + 1}`"
                                    @remove="removeSectionItem('certificate', id)"
                                    @move-up="moveUpSectionItem('certificate', id)"
                                    @move-down="moveDownSectionItem('certificate', id)"
                                    :move-up-disabled="idx == 0"
                                    :move-down-disabled="idx == orders.certificate.length - 1"
                                    :bottom-line="idx < orders.certificate.length - 1"
                                />
                            </div>
                        </ka-cv-form-section>
                    </section>

                    <section class="tw-mt-10">
                        <ka-cv-form-section
                            id="languages"
                            :title="$t('profile.languages')"
                            :sub-title="$t('profile.descriptions.languages')"
                            :addTitle="orders.language.length ? this.$t('global.actions.add_more') : this.$t('global.actions.add')"
                            @add="addSectionItem('language')"
                        >
                            <template #icon>
                                <k-icon name="language" :size="20" />
                            </template>

                            <div class="tw-flex tw-flex-col tw-gap-6">
                                <ka-cv-form-section-item-language
                                    v-for="(id, idx) in orders.language"
                                    :key="`${id}_${idx}`"
                                    v-model="form.language[id]"
                                    @remove="removeSectionItem('language', id)"
                                    @move-up="moveUpSectionItem('language', id)"
                                    @move-down="moveDownSectionItem('language', id)"
                                    :move-up-disabled="idx === 0"
                                    :move-down-disabled="idx === orders.language.length - 1"
                                    :show-input-labels="idx === 0"
                                    :no-border="true"
                                    :dense="true"
                                />
                            </div>
                        </ka-cv-form-section>
                    </section>

                    <!--
                    <div class="tw-hidden lg:tw-block tw-w-full lg:tw-w-84 tw-flex-shrink-0">
                        <aside v-sticky="{ zIndex: 1, stickyTop: 110, disabled: false }">
                            <div
                                v-if="form && form.experience && form.experience.length > 1"
                                class="tw-bg-white tw-rounded-sm tw-p-4 lg:tw-p-8 tw-space-y-2 tw-mt-6"
                            >
                                <h5 class="tw-heading-5">{{ $t('profile.experience_chart') }}</h5>

                                <div v-if="form && form.experience" class="tw-flex tw-justify-center tw-w-full">
                                    <ka-radar-wrapper :invisible-tooltip="true" :cv="form" :size="280" />
                                </div>
                                <div v-else-if="userCv && userCv.experience" class="tw-flex tw-justify-center tw-w-full">
                                    <ka-radar-wrapper :invisible-tooltip="true" :cv="userCv" :size="280" />
                                </div>
                                <div v-else>
                                    <div class="tw-w-full tw-aspect-ratio-1:1 tw-rounded-full"></div>
                                </div>
                                <router-link
                                    :to="{
                                        name: 'blog-detail',
                                        params: { slug: 'radarovy-graf-v-koderia-zivotopise' }
                                    }"
                                    class="tw-text-xs tw-text-black tw-mt-4 tw-inline-block tw-text-center tw-w-full tw-border-0 tw-border-t tw-border-solid tw-border-gray-200 tw-pt-6 hover:tw-text-koderia tw-transition-colors tw-duration-75 tw-ease-in"
                                    target="_blank"
                                >
                                    {{ $t('profile.descriptions.what_is_experience_chart') }}
                                </router-link>
                            </div>

                            <div v-else class="tw-bg-white tw-rounded-sm tw-p-4 lg:tw-p-8 tw-space-y-2 tw-mt-6">
                                <h5 class="tw-heading-5">{{ $t('profile.experience_chart') }}</h5>
                                <div class="tw-pt-2">
                                    <small>{{ $t('profile.descriptions.fill_in_experience_chart') }}</small>
                                    <div class="tw-w-full tw-h-52 tw-aspect-ratio-1:1 tw-mb-4 tw-rounded-full tw-select-none tw-pointer-events-none">
                                        <img src="./assets/chart-placeholder.png" alt="Graf skúseností - Placeholder" class="tw-object-contain" />
                                    </div>
                                </div>

                                <router-link
                                    :to="{
                                        name: 'blog-detail',
                                        params: { slug: 'radarovy-graf-v-koderia-zivotopise' }
                                    }"
                                    class="tw-text-xs tw-text-black tw-mt-4 tw-inline-block tw-text-center tw-w-full tw-border-0 tw-border-t tw-border-solid tw-border-gray-200 tw-pt-6 hover:tw-text-koderia tw-transition-colors tw-duration-75 tw-ease-in"
                                    target="_blank"
                                >
                                    {{ $t('profile.descriptions.fill_in_experience_chart') }}
                                </router-link>
                            </div>
                        </aside>
                    </div>
                    -->
                </div>
            </div>

            <div class="tw-w-full tw-mt-10 lg:tw-mt-0 lg:tw-w-84 tw-flex-shrink-0">
                <aside v-sticky="{ zIndex: 1, stickyTop: 110, disabled: false }">
                    <submit-panel
                        :loading="formState === 'PENDING'"
                        :loading-pdf-url="!pdfUrl"
                        :success="formState === 'SUCCESS'"
                        @submit="submit"
                        @save-pdf="$emit('save-pdf')"
                        @linkedin-import="importFromLinkedin"
                    />

                    <cv-job-offers :experience="form.experience" :profession="form.general.profession" />
                </aside>
            </div>
        </div>
    </div>
</template>

<script>
import Sticky from "vue-sticky";

import SubmitPanel from "./components/cv-submit-panel.vue";

import KaCvFormSection from "./cv-sections/ka-cv-form-section";
import KaUserCvLinkedinModal from "./ka-user-cv-linkedin-modal";
// import KaRadarWrapper from "./../components/ka-radar/radar-wrapper";
import KaCvFormSectionProfile from "./cv-sections/ka-cv-form-section-profile";
import KaCvFormSectionItemLanguage from "./cv-sections/ka-cv-form-section-item-language";
import KaCvFormSectionItemReference from "./cv-sections/ka-cv-form-section-item-reference";
import KaCvFormSectionItemEducation from "./cv-sections/ka-cv-form-section-item-education";
import KaCvFormSectionItemEmployment from "./cv-sections/ka-cv-form-section-item-employment";
import KaCvFormSectionItemExperience from "./cv-sections/ka-cv-form-section-item-experience";
import KaCvFormSectionItemCertificate from "./cv-sections/ka-cv-form-section-item-certificate";
import CvJobOffers from "@web/user/components/cv-job-offers.vue";

// Exclude 'experience' becouse it beaves different
const SECTIONS = ["employment", "education", "reference", "certificate", "language"];

export default {
    directives: {
        sticky: Sticky
    },
    components: {
        CvJobOffers,
        SubmitPanel,
        // KaRadarWrapper,
        KaCvFormSection,
        KaUserCvLinkedinModal,
        KaCvFormSectionProfile,
        KaCvFormSectionItemLanguage,
        KaCvFormSectionItemReference,
        KaCvFormSectionItemEducation,
        KaCvFormSectionItemEmployment,
        KaCvFormSectionItemExperience,
        KaCvFormSectionItemCertificate
    },
    props: {
        preserveForm: {
            type: Boolean,
            default: false
        },
        pdfUrl: {
            type: String
        }
    },
    created() {
        this.fillFormData();
    },
    data() {
        const orders = {};
        const form = {
            general: {}
        };

        form["experience"] = [];
        SECTIONS.forEach(section => {
            orders[section] = [];
            form[section] = {};
        });

        return {
            linkedInImportUsed: false,
            linkedinDialog: false,
            submitted: false,
            gdpr: null,
            form: { ...form },
            orders: { ...orders }
        };
    },
    computed: {
        links() {
            return [
                {
                    href: '#basic-info',
                    offset: 145,
                    label: 'profile.basic_information'
                },
                {
                    href: '#employments',
                    offset: 140,
                    label: 'global.employment'
                },
                {
                    href: '#stack',
                    offset: 140,
                    label: 'profile.stack'
                },
                {
                    href: '#education',
                    offset: 140,
                    label: 'profile.education'
                },
                {
                    href: '#references',
                    offset: 140,
                    label: 'profile.references'
                },
                {
                    href: '#certificates',
                    offset: 140,
                    label: 'profile.certificates'
                },
                {
                    href: '#languages',
                    offset: 140,
                    label: 'profile.languages'
                }
            ]
        },
        user() {
            return this.$store.state.AUTH.user;
        },
        userCv() {
            return this.$store.state.USER.userCv;
        },
        hasUserCv() {
            return this.$store.state.USER.hasUserCv;
        },
        profile() {
            return this.$store.state.USER.profile;
        },
        formState: function() {
            return this.$store.state.USER.formState.userCv;
        },
        formStateMessage: function() {
            return this.$store.state.USER.formStateMessage.userCv;
        },
        allSelectedExperiencesNames() {
            const names = (this.form.experience || []).map(({ name }) => name);
            return names;
        }
    },
    watch: {
        "form": {
            deep: true,
            handler(value) {
                this.$emit("change", value);
            }
        },
        "userCv": {
            immediate: true,
            handler() {
                this.fillFormData();
            }
        },
        "formState"(state) {
            if (state === "SUCCESS") {
                setTimeout(() => {
                    this.$store.commit("USER/SET_FORM_STATE", {
                        name: "userCv",
                        state: "IDLE",
                        message: null
                    });
                }, 2500);
            }
        },
        "form.employment": {
            deep: true,
            handler(employments) {
                // eslint-disable-next-line
                for (const [_key, employment] of Object.entries(employments || {})) {
                    const { technologies } = employment || {};
                    (technologies || []).forEach(tech => {
                        this.addExperience(tech);
                    });
                }
            }
        },
        "form.experience": {
            deep: true,
            handler(experiences = []) {
                const remove = (i, arr) => {
                    let items = [...arr];
                    items = items.slice(0, i).concat(items.slice(i + 1, items.length));
                    return items;
                };

                let newExperiences = [...experiences];
                (experiences || []).forEach((element, idx) => {
                    if (!element.name && !element.experience && idx < experiences.length - 1) {
                        newExperiences = remove(idx, newExperiences);
                    }
                });

                if (JSON.stringify(experiences) !== JSON.stringify(newExperiences)) {
                    this.form.experience = [...newExperiences];
                }
            }
        }
    },
    methods: {
        addExperience(technology) {
            const currentNames = this.allSelectedExperiencesNames;
            if ((currentNames || []).indexOf(technology) === -1) {
                this.form.experience.push({
                    name: technology
                });
            }
        },
        importFromLinkedin() {
            this.linkedinDialog = true;
        },
        fillFormData() {
            if (this.preserveForm === true) {
                return;
            }

            if (this.hasUserCv) {
                const orders = {};
                const form = {};

                const { general = {}, experience = [] } = this.userCv || {};

                const { firstName, lastName, profession, bio, avatar } = this.profile || {};

                form["general"] = {
                    ...general,
                    firstName: firstName || null,
                    lastName: lastName || null,
                    profession: profession || null,
                    avatar: avatar || null,
                    bio: bio || null
                };

                form["experience"] = [...experience];

                SECTIONS.forEach(section => {
                    const orderVal = this.userCv[section] ? Object.keys(this.userCv[section]) : [];
                    orders[section] = orderVal;
                    const formVal = { ...(this.userCv[section] || {}) };
                    form[section] = formVal;
                });

                this.orders = { ...orders };
                this.form = { ...form };
            } else {
                // Prefill data from user profile
                const { email } = this.user || {};
                const { firstName, lastName, profession, bio, avatar, technologies = [] } = this.profile || {};

                const data = {
                    email: email || null,
                    firstName: firstName || null,
                    lastName: lastName || null,
                    profession: profession || null,
                    avatar: avatar || null,
                    bio: bio || null
                };

                this.form.general = data;
                this.form.experience = (technologies || []).map(tech => ({
                    name: tech,
                    experience: null
                }));
                this.form.experience.push({ name: null });
            }
        },
        submit() {
            this.submitted = true;
            const { firstName, lastName, profession, bio, email, web, phoneNumber } = this.form.general || {};
            const { avatar } = this.profile || {};

            const data = {
                general: {
                    firstName: firstName || null,
                    lastName: lastName || null,
                    profession: profession || null,
                    avatar: avatar || null,
                    bio: bio || null,
                    email: email || null,
                    web: web || null,
                    phoneNumber: phoneNumber || null
                }
            };

            data["experience"] = this.form["experience"].filter(({ name, experience }) => name && experience);
            SECTIONS.forEach(section => {
                data[section] = this.orders[section].map(id => this.form[section][id]);
            });

            // CV SETTINGS
            const { settings } = this.userCv || {};
            const { showChart = true, showContact, audience, token, language } = settings || {};
            data["settings"] = {
                showContact: !!showContact,
                showChart: !!showChart,
                audience: audience || "private",
                token: audience === "token" ? token : null,
                language: language || "sk"
            };

            if (this.linkedInImportUsed) {
                data["linkedInImportUsed"] = true;
            }

            this.$store.dispatch("USER/UPDATE_CV", data);
            this.$emit("submit");
        },
        addSectionItem(sectionName, itemData = {}) {
            const newItems = { ...this.form[sectionName] };
            const id = Date.now() + Math.random();
            newItems[id] = itemData;

            if (this.orders[sectionName]) this.orders[sectionName].push(id);
            else this.orders[sectionName] = [id];

            this.form[sectionName] = { ...newItems };
        },
        removeSectionItem(sectionName, id) {
            const newItems = { ...this.form[sectionName] };
            delete newItems[id];
            this.orders[sectionName] = this.orders[sectionName].filter(idx => idx !== id);
            this.form[sectionName] = { ...newItems };
        },
        moveUpSectionItem(sectionName, id) {
            const arr = this.orders[sectionName];
            const idx = arr.indexOf(id);
            this.orders[sectionName] = this.arrayMove(arr, idx, idx > 1 ? idx - 1 : 0);
        },
        moveDownSectionItem(sectionName, id) {
            const arr = this.orders[sectionName];
            const idx = arr.indexOf(id);
            this.orders[sectionName] = this.arrayMove(arr, idx, idx < arr.length - 1 ? idx + 1 : arr.length - 1);
        },
        arrayMove(sourceArr, old_index, new_index) {
            const arr = [...sourceArr];
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr;
        },
        fillLinkedinImportResponse(response) {
            this.linkedInImportUsed = true;

            (response || []).forEach(section => {
                // try {
                //     // Experience
                //     if (section.name === "skills") {
                //         (section.content || []).forEach(item => {
                //             this.addExperience(item["Name"]);
                //         });
                //     }
                // } catch (e) {
                //     console.error(e);
                // }

                try {
                    // Certificate
                    if (section.name === "courses") {
                        (section.content || []).forEach(item => {
                            this.addSectionItem("certificate", {
                                name: item["Name"]
                            });
                        });
                    }
                } catch (e) {
                    console.error(e);
                }

                try {
                    // Education
                    if (section.name === "education") {
                        (section.content || []).forEach(item => {
                            this.addSectionItem("education", {
                                grade: item["Degree Name"],
                                school: item["School Name"],
                                city: "",
                                country: "",
                                from: +item["Start Date"],
                                to: +item["End Date"] || "dnes",
                                description: item["Notes"]
                            });
                        });
                    }
                } catch (e) {
                    console.error(e);
                }

                try {
                    // Employment
                    if (section.name === "positions") {
                        const year = val => {
                            const y = (val || "").split(" ")[1];
                            return +y;
                        };

                        const month = val => {
                            const mMap = {
                                Jan: "január",
                                Feb: "február",
                                Mar: "marec",
                                Apr: "apríl",
                                May: "máj",
                                Jun: "jún",
                                Jul: "júl",
                                Aug: "august",
                                Sep: "september",
                                Oct: "október",
                                Nov: "november",
                                Dec: "december"
                            };
                            const m = (val || "").split(" ")[0];
                            return mMap[m];
                        };

                        (section.content || []).forEach(item => {
                            const employment = {
                                position: item["Title"],
                                company: item["Company Name"],
                                toMonth: month(item["Finished On"]) || "dnes",
                                toYear: year(item["Finished On"]),
                                fromMonth: month(item["Started On"]),
                                fromYear: year(item["Started On"]),
                                description: item["Description"]
                            };
                            this.addSectionItem("employment", employment);
                        });
                    }
                } catch (e) {
                    console.error(e);
                }

                try {
                    // Language
                    if (section.name === "languages") {
                        const levelsMap = {
                            "native or bilingual proficiency": "native-speaker",
                            "full professional proficiency": "expert",
                            "professional working proficiency": "advanced",
                            "limited working proficiency": "intermediate",
                            "elementary proficiency": "beginner"
                        };

                        (section.content || []).forEach(item => {
                            this.addSectionItem("language", {
                                language: item["Name"],
                                level: levelsMap[String(item["Proficiency"]).toLowerCase()] || "beginner"
                            });
                        });
                    }
                } catch (e) {
                    console.error(e);
                }

                try {
                    // Profile
                    if (section.name === "profile") {
                        this.form.general.firstName = this.form.general.firstName || section.content[0]["First Name"];
                        this.form.general.lastName = this.form.general.lastName || section.content[0]["Last Name"];
                        this.form.general.bio = this.form.general.bio || section.content[0]["Summary"];
                    }
                } catch (e) {
                    console.error(e);
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.mt-13 {
    margin-top: 3.25rem;
}

.edit-in-profile-reminder {
    margin-top: -1rem;
}
</style>
