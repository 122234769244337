const LANGUAGE_LEVELS = {
    sk: {
        "native-speaker": "Materinský jazyk",
        "expert": "Expert",
        "advanced": "Pokročilý",
        "intermediate": "Mierne pokročilý",
        "beginner": "Začiatočník"
    },
    en: {
        "native-speaker": "Native Speaker",
        "expert": "Expert",
        "advanced": "Advanced",
        "intermediate": "Intermediate",
        "beginner": "Beginner"
    }
};

const CEFR_LEVELS = {
    sk: {
        A1: "A1",
        A2: "A2",
        B1: "B1",
        B2: "B2",
        C1: "C1",
        C2: "C2"
    },
    en: {
        A1: "A1",
        A2: "A2",
        B1: "B1",
        B2: "B2",
        C1: "C1",
        C2: "C2"
    }
};

export { LANGUAGE_LEVELS, CEFR_LEVELS };
