<template>
    <div class="wrapper tw-bg-white tw-rounded-2xl tw-p-4 lg:tw-p-8">
        <section-header>
            <template #icon>
                <slot name="icon" />
            </template>

            <template #title>
                <h3 :id="id || title" class="tw-heading-4">{{ title }}</h3>
            </template>

            <template v-if="subTitle" #subtitle>
                {{ subTitle }}
            </template>
        </section-header>

        <slot name="divider">
            <div class="tw-mt-6 tw-mb-6 tw-border-gray-100 tw-border-b-2" />
        </slot>

        <slot />

        <div v-if="!simple" :class="!!$slots.default ? 'tw-mt-8' : 'tw-mt-2'">
            <k-button @click="$emit('add')" color="regular">
                {{ addTitle }}
            </k-button>
        </div>
    </div>
</template>

<script>
import SectionHeader from '@web/user/components/section-header'
export default {
    components: {SectionHeader},
    props: {
        simple: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        id: {
            type: String,
        },
        subTitle: {
            type: String
        },
        addTitle: {
            type: String
        }
    }
};
</script>
