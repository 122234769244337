<template>
    <div class="tw-mt-10 md:tw-mt-20">
        <h4 class="tw-text-sm tw-leading-normal tw-mb-2 tw-text-koderia">{{ $t('profile.job_listing_title') }}</h4>

        <div class="tw-flex tw-flex-col tw-gap-4">
            <div
                v-for="project in recommendedProjects"
                :key="project.id"
                class="tw-rounded-2xl tw-bg-white tw-px-8 tw-pt-8 tw-pb-10 tw-border-2 tw-border-koderia"
            >
                <h3 class="tw-text-xl tw-text-koderia tw-mb-3 tw-font-bold title">{{ project.name }}</h3>

                <div class="tw-flex tw-items-center tw-gap-x-1.5 tw-mb-5">
                    <span class="tw-text-xl">€</span>
                    {{ project.rateContractFrom }} - {{ project.rateContractTo }} € / manday
                </div>

                <k-button
                    color="secondary"
                    class="tw-w-full"
                    :to="{ name: 'project-detail', params: { id: project.id, locale }}"
                >
                    {{ $t('calculator.actions.get_hired') }}
                </k-button>
            </div>
        </div>
    </div>
</template>

<script>
import { PROGRAMMER_PROFESSIONS, TESTER_PROFESSIONS } from "@web/constants/questionaire";
import { programmerCalculator, testerCalculator } from "./../../addons/questionaire_calculator";
import { getRouteLocale } from "@web/constants/language";
import { getProjectsByProfessionAndWage } from "@web/utils/projects";
import { mapGetters } from "vuex";
import KButton from "@web/components/core/k-button.vue";

export default {
    components: {KButton},
    props: {
        experience: {
            type: Array,
            required: true
        },
        profession: {
            type: String,
            default: ""
        }
    },
    computed: {
        ...mapGetters("NEW_PROJECTS", ["allProjects"]),
        locale() {
            return getRouteLocale()
        },
        wage() {
            return this.calculateWage(this.experience) || 0;
        },
        recommendedProjects() {
            const formResults = {
                profession: this.profession,
                data: {
                    wage: this.wage,
                    technologiesKnown: (this.experience || []).map(item => item.name).filter(Boolean)
                }
            }

            return getProjectsByProfessionAndWage(this.allProjects, formResults)
        }
    },
    methods: {
        calculateWage(experience) {
            try {
                const technologiesKnown = [];
                const technologiesPraxis = [];

                const mapExperience = {
                    "0-1": 0,
                    "1": 1,
                    "2": 2,
                    "3": 3,
                    "4": 4,
                    "5-10": 5,
                    "10+": 6
                };

                (experience || []).forEach(element => {
                    if (!element.experience) {
                        return;
                    }

                    technologiesKnown.push(element.name);
                    technologiesPraxis.push({
                        name: element.name,
                        praxis: mapExperience[element.experience]
                    });
                });

                const input = {
                    technologiesKnown,
                    technologiesPraxis
                };

                if (PROGRAMMER_PROFESSIONS.includes(this.profession)) {
                    programmerCalculator.processInput(input);
                    programmerCalculator.computeWage(input);
                } else if (TESTER_PROFESSIONS.includes(this.profession)) {
                    testerCalculator.processInput(input);
                    testerCalculator.computeWage(input);
                }

                const { wage = 0 } = input || {};
                return wage;
            } catch (error) {
                return -1;
            }
        }
    }
};
</script>

<style scoped>
.technologies-wrapper {
    overflow: hidden;
    height: 32px;
}
</style>
