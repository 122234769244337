<template>
    <ka-cv-form-section-item-base v-bind="$attrs" v-on="$listeners">
        <div class="tw-grid tw-gap-4">
            <div class="tw-flex tw-flex-col-reverse sm:tw-flex-row tw-space-y-reverse tw-space-y-4 sm:tw-space-y-0 sm:tw-space-x-4">
                <k-input
                    v-model="form.position"
                    :title="$t('global.inputs.position')"
                    class="tw-w-full"
                />
                <ka-cv-form-section-item-actions
                    @move-up="$emit('move-up')"
                    @move-down="$emit('move-down')"
                    @remove="$emit('remove')"
                    :moveUpDisabled="moveUpDisabled"
                    :moveDownDisabled="moveDownDisabled"
                    class="tw-items-end"
                />
            </div>

            <k-input
                v-model="form.company"
                :title="$t('global.inputs.company')"
            />

            <div class="tw-flex tw-flex-col sm:tw-flex-row tw-space-y-4 sm:tw-space-y-0 sm:tw-space-x-14">
                <div class="tw-w-full has-divider-after">
                    <label class="tw-font-bold tw-pb-2 tw-inline-block tw-text-gray-800 tw-text-sm">{{ $t('global.start') }}</label>
                    <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                        <k-select
                            :items="months()"
                            v-model="form.fromMonth"
                            :placeholder="$t('global.time.month')"
                        />
                        <k-select
                            :items="years()"
                            v-model="form.fromYear"
                            :placeholder="$t('global.time.year')"
                        />
                    </div>
                </div>

                <div class="tw-w-full">
                    <label class="tw-font-bold tw-pb-2 tw-inline-block tw-text-gray-800 tw-text-sm">{{ $t('global.end') }}</label>
                    <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
                        <k-select
                            :items="[$t('global.time.today'), ...months()]"
                            v-model="form.toMonth"
                            :placeholder="$t('global.time.month')"
                        />
                        <k-select
                            :items="years()"
                            v-model="form.toYear"
                            :placeholder="$t('global.time.year')"
                            :style="`opacity: ${hideToYear ? 0 : 1}; visibility: ${hideToYear ? 'hidden' : 'visible'}`"
                        />
                    </div>
                </div>
            </div>

            <k-autocomplete-technologies
                :label="$t('profile.inputs.stack')"
                :sub-title="$t('profile.descriptions.stack_input')"
                v-model="form.technologies"
                :items="allTechnologies"
                :showLimit="3"
            />
            <k-textarea
                title="Description"
                :sub-title="$t('profile.descriptions.employment_description_input')"
                v-model="form.description"
                class="tw-mt-2"
            />
        </div>
    </ka-cv-form-section-item-base>
</template>

<script>
import { mapGetters } from "vuex";

import KaCvFormSectionItemBase from "./ka-cv-form-section-item-base";
import KaCvFormSectionItemActions from "./ka-cv-form-section-item-actions";

export default {
    components: {
        KaCvFormSectionItemBase,
        KaCvFormSectionItemActions
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        moveUpDisabled: {
            type: Boolean,
            default: false
        },
        moveDownDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hideToYear: false,
            form: { ...this.extractDataModelFrom("value") }
        };
    },
    watch: {
        "form": {
            deep: true,
            handler() {
                this.$emit("input", this.extractDataModelFrom("form"));
            }
        },
        "form.toMonth": {
            immediate: true,
            handler(v) {
                // Select 'todey' by default
                if (!v || String(v).trim().length < 1) {
                    this.form.toMonth = "dnes";
                    return;
                }

                if (v === "dnes") {
                    this.hideToYear = true;
                    this.form.toYear = null;
                } else {
                    this.hideToYear = false;
                }
            }
        }
    },
    computed: {
        ...mapGetters("ENUMS", {
            allTechnologies: "allTechnologiesNames"
        })
    },
    methods: {
        months() {
            return [
                this.$t('global.time.january'),
                this.$t('global.time.february'),
                this.$t('global.time.march'),
                this.$t('global.time.april'),
                this.$t('global.time.may'),
                this.$t('global.time.june'),
                this.$t('global.time.july'),
                this.$t('global.time.august'),
                this.$t('global.time.september'),
                this.$t('global.time.october'),
                this.$t('global.time.november'),
                this.$t('global.time.december')
            ];
        },
        years(backTo = 100) {
            const now = new Date().getUTCFullYear();
            const years = Array(now - (now - backTo))
                .fill("")
                .map((v, idx) => now - idx);
            return years;
        },
        extractDataModelFrom(variableName) {
            const {
                position = null,
                company = null,
                fromMonth = null,
                fromYear = null,
                toMonth = null,
                toYear = null,
                technologies = null,
                description = null
            } = this[variableName] || {};

            return {
                position,
                company,
                fromMonth,
                fromYear,
                toMonth,
                toYear,
                technologies,
                description
            };
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.has-divider-after {
    position: relative;
}

.has-divider-after::after {
    content: "";
    position: absolute;
    width: 1.5rem;
    height: 0.125rem;
    background-color: #edeff2;
    right: -2.5rem;
    bottom: 1.375rem;

    display: none;
    @include sm {
        display: block;
    }
}
</style>
