import { render, staticRenderFns } from "./cv-submit-panel.vue?vue&type=template&id=4498e2c0&"
import script from "./cv-submit-panel.vue?vue&type=script&lang=js&"
export * from "./cv-submit-panel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KGdpr from '@web/components/core/k-gdpr'
import KIcon from '@web/components/core/k-icon'
installComponents(component, {KButton,KGdpr,KIcon})
